<template>
    <icon-base width="200" height="100" :fill="color" viewBox="200 150 300 350">
        <g>
            <path d="m632.59 265.99c-37.211 0-67.41 30.199-67.41 67.41 0 37.211 30.199 67.41 67.41 67.41 37.211 0 67.41-30.199 67.41-67.41 0-37.211-30.336-67.41-67.41-67.41zm0 120.94c-29.527 0-53.523-24-53.523-53.523 0-29.527 24-53.523 53.523-53.523 29.527 0 53.523 24 53.523 53.523 0 29.523-24 53.523-53.523 53.523z"/>
            <path d="m624.77 314.52c0 4.7656-3.8633 8.6289-8.6289 8.6289s-8.6289-3.8633-8.6289-8.6289c0-4.7656 3.8633-8.6289 8.6289-8.6289s8.6289 3.8633 8.6289 8.6289"/>
            <path d="m657.93 314.52c0 4.7656-3.8633 8.6289-8.6289 8.6289-4.7617 0-8.625-3.8633-8.625-8.6289 0-4.7656 3.8633-8.6289 8.625-8.6289 4.7656 0 8.6289 3.8633 8.6289 8.6289"/>
            <path d="m661.57 337.45c-2.0234 14.293-14.293 25.348-29.121 25.348-14.832 0-27.098-11.055-29.121-25.348h-10.516c2.0234 19.953 19.012 35.594 39.504 35.594 20.492 0 37.48-15.641 39.504-35.594z"/>
            <path d="m490.75 265.31c-37.211 0-67.41 30.199-67.41 67.41 0 37.211 30.199 67.41 67.41 67.41 37.211 0 67.41-30.199 67.41-67.41 0.003907-37.207-30.199-67.41-67.41-67.41zm0 120.94c-29.527 0-53.523-24-53.523-53.523 0-29.527 24-53.523 53.523-53.523 29.527 0 53.523 24 53.523 53.523 0.003906 29.527-23.996 53.523-53.523 53.523z"/>
            <path d="m482.93 313.85c0 4.7656-3.8633 8.6289-8.6289 8.6289-4.7617 0-8.625-3.8633-8.625-8.6289 0-4.7656 3.8633-8.6289 8.625-8.6289 4.7656 0 8.6289 3.8633 8.6289 8.6289"/>
            <path d="m516.24 313.85c0 4.7656-3.8633 8.6289-8.6289 8.6289s-8.6289-3.8633-8.6289-8.6289c0-4.7656 3.8633-8.6289 8.6289-8.6289s8.6289 3.8633 8.6289 8.6289"/>
            <path d="m513.14 346.48c-6.3359 3.5039-14.023 5.6641-22.379 5.6641-8.3594 0-16.18-2.1562-22.379-5.6641-2.6953-1.4844-6.3359-1.0781-8.3594 0.94531-2.0234 2.0234-1.3477 4.8555 1.2148 6.3359 8.2227 4.582 18.473 7.4141 29.527 7.4141 11.055 0 21.301-2.832 29.527-7.4141 2.6953-1.4844 3.2344-4.3125 1.2148-6.3359-2.0312-2.0234-5.6719-2.4258-8.3672-0.94531z"/>
            <path d="m350.54 265.99c-37.211 0-67.41 30.199-67.41 67.41 0 37.211 30.199 67.41 67.41 67.41 37.211 0 67.41-30.199 67.41-67.41 0-37.211-30.199-67.41-67.41-67.41zm0 120.94c-29.527 0-53.523-24-53.523-53.523 0-29.527 24-53.523 53.523-53.523 29.527 0 53.523 24 53.523 53.523 0 29.523-23.996 53.523-53.523 53.523z"/>
            <path d="m342.72 314.52c0 4.7656-3.8633 8.6289-8.6289 8.6289s-8.6289-3.8633-8.6289-8.6289c0-4.7656 3.8633-8.6289 8.6289-8.6289s8.6289 3.8633 8.6289 8.6289"/>
            <path d="m376.02 314.52c0 4.7656-3.8633 8.6289-8.6289 8.6289s-8.6289-3.8633-8.6289-8.6289c0-4.7656 3.8633-8.6289 8.6289-8.6289s8.6289 3.8633 8.6289 8.6289"/>
            <path d="m382.63 359.82c2.0234-2.0234 1.3477-4.8555-1.2148-6.3359-8.2227-4.582-50.828-4.582-59.051 0-2.6953 1.4844-3.2344 4.3125-1.2148 6.3359 1.7539 1.8867 59.859 1.7539 61.48 0z"/>
            <path d="m208.98 265.31c-37.211 0-67.41 30.199-67.41 67.41 0 37.211 30.199 67.41 67.41 67.41 37.211 0 67.41-30.199 67.41-67.41 0-37.207-30.199-67.41-67.41-67.41zm0 120.94c-29.527 0-53.523-24-53.523-53.523 0-29.527 24-53.523 53.523-53.523 29.527 0 53.523 24 53.523 53.523 0 29.527-24 53.523-53.523 53.523z"/>
            <path d="m201.16 313.85c0 4.7656-3.8633 8.6289-8.6289 8.6289s-8.6289-3.8633-8.6289-8.6289c0-4.7656 3.8633-8.6289 8.6289-8.6289s8.6289 3.8633 8.6289 8.6289"/>
            <path d="m234.46 313.85c0 4.7656-3.8633 8.6289-8.6289 8.6289s-8.6289-3.8633-8.6289-8.6289c0-4.7656 3.8633-8.6289 8.6289-8.6289s8.6289 3.8633 8.6289 8.6289"/>
            <path d="m186.46 360.09c6.3359-3.5039 14.023-5.6641 22.379-5.6641 8.3594 0 16.18 2.0234 22.379 5.5273 2.6953 1.4844 6.3359 1.0781 8.3594-0.94531 2.0234-2.0234 1.3477-4.8555-1.2148-6.3359-8.2227-4.582-18.473-7.4141-29.527-7.4141-11.055 0-21.301 2.832-29.527 7.4141-2.6953 1.4844-3.2344 4.3125-1.2148 6.3359 2.0312 2.1602 5.8047 2.5664 8.3672 1.082z"/>
            <path d="m67.41 265.99c-37.211 0-67.41 30.199-67.41 67.41 0 37.211 30.199 67.41 67.41 67.41 37.211 0 67.41-30.199 67.41-67.41 0.003907-37.211-30.199-67.41-67.41-67.41zm0 120.94c-29.527 0-53.523-24-53.523-53.523 0-29.527 24-53.523 53.523-53.523 29.527 0 53.523 24 53.523 53.523 0.003906 29.523-23.996 53.523-53.523 53.523z"/>
            <path d="m59.59 314.52c0 4.7656-3.8633 8.6289-8.625 8.6289-4.7656 0-8.6289-3.8633-8.6289-8.6289 0-4.7656 3.8633-8.6289 8.6289-8.6289 4.7617 0 8.625 3.8633 8.625 8.6289"/>
            <path d="m92.895 314.52c0 4.7656-3.8633 8.6289-8.6289 8.6289s-8.6289-3.8633-8.6289-8.6289c0-4.7656 3.8633-8.6289 8.6289-8.6289s8.6289 3.8633 8.6289 8.6289"/>
            <path d="m67.547 341.49c-16.18-0.13672-30.199 9.4375-36.535 23.324h11.863c5.3945-7.9531 14.426-13.078 24.539-12.941 9.8438 0.13672 18.336 4.9883 23.594 12.402 3.9102 0 7.9531-0.13672 11.863-0.26953-6.2031-13.078-19.684-22.246-35.324-22.516z"/>
        </g>
    </icon-base>
</template>

<script>
export default {
name: 'iconFaceRatings',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
    type: Number,
    default: 20
    },
    color: {
        type: String,
        // default: '#8cb24b'
    },
    
}
}
</script>

<style scoped>
    /* .st14 {
        fill: #8cb24b;
    } */
    .st5 {
        fill: #ffffff;
    }
</style>
