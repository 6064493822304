<template>
    <icon-base :width="size + 'px'" :fill="color" class="icon-edit">
        <g id="Layer_21">
            <g>
                <g>
                    <g>
                        <g>
                            <g>
                                <path class="st0" d="M242.4,151.7c-3.6,0-6.5,2.9-6.5,6.5v57.9c0,10.8-8.8,19.6-19.6,19.6H33.1c-10.8,0-19.6-8.8-19.6-19.6        v-170c0-10.8,8.8-19.6,19.6-19.6H91c3.6,0,6.5-2.9,6.5-6.5s-2.9-6.5-6.5-6.5H33.1c-18,0-32.6,14.6-32.6,32.6v170.1        c0,18,14.6,32.6,32.6,32.6h183.1c18,0,32.6-14.6,32.6-32.6v-57.9C248.9,154.7,246,151.7,242.4,151.7z"/>
                                <path class="st0" d="M102.5,117.4l95.3-95.3l30.7,30.7l-95.3,95.3L102.5,117.4z"/>
                                <path class="st0" d="M87,163.7l34-9.4l-24.6-24.6L87,163.7z"/>
                                <path class="st0" d="M237.1,5.9c-6.4-6.4-16.7-6.4-23.1,0l-6.9,6.9l30.7,30.7l6.9-6.9c6.4-6.4,6.4-16.7,0-23.1L237.1,5.9z"/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </icon-base>
</template>
<script>

export default {
name: 'iconEdit',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
}
}
</script>

<style scoped>
</style>
