<template>
    <icon-base :width="size + 'px'" :fill="color">
        <g id="Layer_2">
            <path d="M101.4,2.7C57.8,10.8,20.8,42.6,7,84.7c-11.4,34.6-7.5,70.8,9.2,100.9c1,1.7,1.3,3.8,0.7,5.7L6.3,229.6
                c-2.4,8.6,5.5,16.5,14.1,14.1l38.3-10.6c1.9-0.5,4-0.2,5.7,0.7c30,16.8,66.2,20.6,100.9,9.2c42.1-13.8,74-50.8,82-94.4
                C263.4,61.6,188.4-13.4,101.4,2.7z M133.1,187.3c-2.7,2.3-5.6,3.4-9,3.4c-3.5,0-6.6-1.1-9.3-3.4c-2.7-2.2-3.9-5.5-3.9-9.5
                c0-3.7,1.2-6.7,3.8-9.3c2.6-2.4,5.6-3.7,9.4-3.7c3.7,0,6.7,1.2,9.2,3.7c2.4,2.6,3.8,5.6,3.8,9.3C137,181.8,135.6,185,133.1,187.3z
                M165.5,106.8c-2,3.7-4.4,6.8-7.1,9.5c-2.8,2.8-7.7,7.3-14.9,13.7c-2,1.7-3.5,3.3-4.6,4.6c-1.2,1.5-2.1,2.7-2.7,3.8
                c-0.6,1.1-1,2.2-1.3,3.4c-0.2,1.1-0.7,3.1-1.5,5.9c-1.1,6.1-4.5,9-10.3,9c-3.1,0-5.6-1-7.6-2.9c-2.1-2-3.2-4.9-3.2-8.8
                c0-4.8,0.9-9,2.3-12.6c1.5-3.5,3.5-6.7,6-9.4c2.4-2.7,5.9-5.9,10-9.5c3.8-3.3,6.5-5.7,8.1-7.3c1.7-1.7,3.1-3.5,4.2-5.5
                c1.1-2.1,1.7-4.3,1.7-6.6c0-4.6-1.7-8.6-5.1-11.7c-3.4-3.2-7.9-4.8-13.3-4.8c-6.4,0-11.1,1.6-14.1,4.8c-3.1,3.2-5.6,7.9-7.7,14.2
                c-2,6.6-5.7,9.8-11.2,9.8c-3.2,0-6-1.1-8.2-3.4c-2.2-2.2-3.3-4.8-3.3-7.3c0-5.5,1.7-11.1,5.3-16.8c3.5-5.6,8.7-10.4,15.5-14.1
                c6.7-3.7,14.7-5.5,23.7-5.5c8.3,0,15.8,1.5,22.3,4.6c6.4,3.1,11.4,7.2,14.8,12.6c3.5,5.4,5.3,11.1,5.3,17.4
                C168.4,98.8,167.4,103.2,165.5,106.8z"/>
        </g>
    </icon-base>
</template>

<script>
export default {
name: 'iconCustomerService',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
}
}
</script>

<style scoped>

</style>
