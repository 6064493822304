<template>
    <main-modal @closeModal="$emit('closeModal')" :title="'Detalles del ticket'" class="ticket-details">
        <!-- {{ticket}} -->
        <div class="row d-flex align-items-center">
            <avatar class="col-md-6" :customerImg="user.entity.photo_url" :name="user.entity.first_name" :lastname="user.entity.last_name" :showStar="false"
            :date="ticket.created_at"/>
            <div class="d-flex col-md-6 justify-content-end">
                <main-button :title="'Responder'" class="mx-2" @click="addComment = true"/>
                <second-button :title="'Cerrar'" @click="showCloseTicket = true" v-if="ticket.status != 5"/>
            </div>
        </div>
        <h3 class="title-18 mt-2">{{ticket.subject}}</h3>
        <p class="color-dark-gray-400 weight-100 mb-1">
            {{ticket.description_text}}.
        </p>
        <transition-group class="d-flex mb-4 flex-wrap" name="blink" mode="out-in">
            <div v-for="(img, index) in ticket.attachments.slice(0, previewImgs)" :key="img.id" class="container-img position-relative" @click="openLightBox(img.attachment_url)">
                <image-ticket :source="img.attachment_url" class="position-relative" />
                <div v-if="index == (previewImgs - 1) && ticket.attachments.length > previewImgs" class="show-rest-img position-absolute d-flex align-content-center align-items-center justify-content-center bg-dark-main-navy">
                    <span class="size-26 color-white">+{{ticket.attachments.length - ticket.attachments.slice(0, previewImgs).length}}</span>
                </div>
            </div>
        </transition-group>
        <light-box :images="ticket.attachments" :active="this.selectedImg" v-if="showLightBox" @closeLightBox="showLightBox = false"/>
        <icon-face-ratings :size="200" v-if="false"/>
        <div id="conversation">
            <div v-for="response in ticket.conversations" :key="response.id">
                <avatar :customerImg="require('../../../../common/assets/images/logo-sm.png')" :name="'Mundo'" :lastname="'Movil'" :showStar="false"
                :date="response.created_at" class="mb-md-0" v-if="response.support_email"/>
                <avatar :customerImg="user.entity.photo_url" :name="user.entity.first_name" :lastname="user.entity.last_name" :showStar="false"
                :date="response.created_at" class="mb-md-0" v-else/>
                <div style="padding-left: 57px;">
                    <p class="color-dark-gray-400 weight-300" style="word-break: break-all;">{{response.body_text}}</p>
                </div>
            </div>
        </div>
        <div v-if="addComment" class="mt-4">
            <quill-editor ref="myQuillEditor" v-model="content"/>
            <div class="d-flex mt-4">
                <main-button :title="'Responder'" @click="addNoteToTicket" class="me-2"/>
                <second-button :title="'Cancelar'" @click="addComment = false"/>
            </div>
        </div>
        <div class="input-add-comment py-2 px-4 cursor-pointer mt-4" @click="addComment = true" v-else>
            Click aqui para anadir un comentario
        </div>
        <close-ticket v-if="showCloseTicket" @closeModal="showCloseTicket = false" @confirm="closeIssue" :id="this.ticket.id"/>
            <!-- @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)"
            @ready="onEditorReady($event)" -->
    </main-modal>
</template>

<script>
import { mapGetters } from "vuex"
import MainButton from '../../../../common/components/buttons/MainButton.vue'
import SecondButton from '../../../../common/components/buttons/SecondButton.vue'
import MainModal from "../../../../common/components/modals/MainModal.vue"
import Avatar from '../../../products/components/chat/Avatar.vue'
import ImageTicket from '../../../products/components/Image.vue'
import LightBox from '../../../products/components/modals/LightBox.vue'
import http from 'axios';
import CloseTicket from './CloseTicket.vue'
import IconFaceRatings from '../../../../common/svg/iconFaceRatings.vue'

    export default {
        name: "TicketDetails",
        components: { MainModal, Avatar, ImageTicket, LightBox, MainButton, SecondButton, CloseTicket, IconFaceRatings },
        props:{
            ticket:{
                type: Object
            }
        },
        data(){
            return{
                previewImgs: null,
                showLightBox: false,
                selectedImg: null,
                content: '',
                addComment: false,
                api: 'https://mundomovil-support.freshdesk.com/api/v2/',
                showCloseTicket: false
            }
        },
        computed:{
            ...mapGetters({
                user: "auth/userData"
            }),
        },
        methods:{
            openLightBox(img){
                this.ticket.attachments.forEach(img => {
                    img['picture_url'] = img.attachment_url
                });
                console.log(this.ticket.attachments);
                this.selectedImg = img
                this.showLightBox = true
            },
            async getContactByEmail(){
                try {
                    const response = await http.get(`${this.api}contacts`, 
                        {
                            auth: {
                                username: 'dbss7KE5OdAuKTy3L',
                                password: 'x',
                            },
                            params:{
                                email: this.user.email,
                            }
                        }
                    )
                    return response.data
                } catch (error) {
                    console.log(error);
                }
            },
            async addNoteToTicket(){
                try {
                    const contact = await this.getContactByEmail()
                    await http.post(`${this.api}tickets/${this.ticket.id}/notes`, 
                        {
                            body: this.content,
                            user_id: contact[0].id,
                        },
                        {
                        auth: {
                                username: 'dbss7KE5OdAuKTy3L',
                                password: 'x',
                            },
                        }
                    )
                    this.$emit('closeModal')
                } catch (error) {
                    console.log(error);
                }
            },
            async closeIssue(){
                try {
                    await http.put(`${this.api}tickets/${this.ticket.id}`, 
                        {
                            status: 5,
                            // user_id: contact[0].id,
                        },
                        {
                        auth: {
                                username: 'dbss7KE5OdAuKTy3L',
                                password: 'x',
                            },
                        }
                    )
                    this.$emit('refresh')
                    this.$emit('closeModal')
                } catch (error) {
                    console.log(error);
                }
            }
        },
        created(){
            this.previewImgs = window.matchMedia('(min-width: 768px)').matches ? 5 : 7;
        }
    }
</script>
<style scoped>
    .image__wrapper::v-deep{
        border: 1px solid var(--light-gray-100);
    }
    .image__wrapper::v-deep, .image__wrapper::v-deep img{
        width: 100px !important;
        height: 100px;
        border-radius: 8px;
        object-fit: cover !important;
    }
    .container-img:hover > .image__wrapper::v-deep img{
        transform: scale(1.1);
    }
    .container-img {
        overflow: hidden;
        border-radius: 10px;
    }
    .container-img:hover::before{
        cursor: pointer;
        content: '';
        background: #212e3d58;
        position: absolute;
        width: 100%;
        z-index: 10;
        height: 100%;
        animation: show-mask 0.3s;
        border-radius: 10px;
    }
    .input-add-comment{
        border: 1px solid var(--gray-100);
        border-radius: 7px;
    }
    .quill-editor::v-deep > .ql-toolbar{
        border-radius: 7px 7px 0px 0px;
        border-color: var(--gray-100);
    }
    .quill-editor::v-deep > .ql-container{
        border-radius: 0px 0px 7px 7px;
        border-color: var(--gray-100);
        min-height: 200px;
    }
    #conversation{
        max-height: 200px;
        overflow: auto;
        padding-right: 10px;
        margin-bottom: 10px;
    }
    @media (min-width: 1200px) {
        .ticket-details.modal-main-container::v-deep > .modal-main{
            width: 1000px;
            max-width: 1000px;
        }
    }
</style>

