<template>
    <main-confirm @cancel="$emit('closeModal')" @confirm="$emit('confirm')" :confirmTitle="'Confirmacion'" :description="'Seguro que deseas cerrar este ticket'">
        <icon-warning :size="50"/>
    </main-confirm>
</template>

<script>
import MainConfirm from "../../../../common/components/modals/MainConfirm.vue"
import IconWarning from '../../../../common/svg/iconWarning.vue'

    export default {
        name: "CloseTicket",
        components:{
            MainConfirm,
                IconWarning
        }
    }
</script>
