<template>
    <div>
        <div class="row">
            <div class="col-md-4">
                <form class="ticket-form" @submit.prevent="">
                    <h5 class="title-28">{{$t('account.create_ticket')}}</h5>
                    <main-input ref="ticketSubject" :value="form.subject" title="account.subject" id="ticket-subject" @changeValue="(data) => {form.subject = data}">
                    </main-input>
                    <label for="suject" class="title-14">{{$t('account.category')}}</label>
                    <main-select ref="category" :selectedOption="selectedOption" @clickHandler="(data) => selectedOption = data" defaultInvalid summaryRef="select-category" :options="filterOptions" class="mb-3" />
                    <div class="d-flex flex-column">
                        <label for="suject" class="title-14">{{$t('account.description')}}</label>
                        <textarea v-model="form.description" name="description" id="description" cols="30" rows="10" class="form-control" maxlength="255"></textarea>
                    </div>
                    <vue-dropzone ref="dropzoneReview" id="imgDropZone" class="position-relative mt-3" :useCustomSlot="true"
                        :options="dropzoneOptions" @vdropzone-file-added="afterComplete" :max-file-size-in-m-b="20" :max-number-of-files="6" >
                            <div>
                                <icon-add-image :size="60" :color="'var(--gray-1001)'"/>
                                <h6 class="m-0 my-1" style="font-family: 'Rubik'; font-weight: 500;">{{$t('checkout.upload_image')}}</h6>
                                <span style="color: #a5b2b7; font-family: 'Rubik'; font-weight: 300;">{{$t('checkout.drag_or_upload_your_voucher_here')}}</span>
                            </div>
                    </vue-dropzone>
                    <div class="d-flex mt-4">
                        <main-button title="account.save" id="ticket-btn-save" @click="createTicket" class="ms-auto"></main-button>
                    </div>
                </form>
            </div>
            <div class="col-md-8">
                <card v-for="ticket in tickets" :key="ticket.id" class="mt-4">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="d-flex align-items-center">
                            <div class="ticket-icon">
                                <icon-customer-service :color="'#45ccfe'"/>
                            </div>
                            <b class="ms-3">Ticket #{{ticket.id}}</b>
                        </div>
                        <div class="color-dark-gray-200">
                                {{ $d(new Date(ticket.created_at), 'short')  | textFormat}}
                            <b>
                                {{ $d(new Date(ticket.created_at), 'standard_hour')  | textFormat}}
                            </b>
                            <!-- <svg width="21px" height="21px" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
                                <g fill="currentColor" fill-rule="evenodd">
                                    <circle cx="10.5" cy="10.5" r="1"/>
                                    <circle cx="5.5" cy="10.5" r="1"/>
                                    <circle cx="15.5" cy="10.5" r="1"/>
                                </g>
                            </svg> -->
                        </div>
                    </div>
                    <h5 class="m-0 mt-4 weight-600 size-16">
                        {{ticket.subject}}
                    </h5>
                    <p class="mb-0 mt-2 color-dark-gray-300 size-15">
                        {{ticket.description_text}}
                    </p>
                    <div class="mt-3 d-flex justify-content-between align-items-center">
                        <div>
                            <img :src="user.entity.photo_url" class="profile-image" alt="profile">
                            <span class="ms-2 color-dark-gray-200 size-15">
                                {{user.entity.first_name + ' ' + user.entity.last_name}}
                            </span>
                            <a href="#" class="ms-2" @click.prevent="getDetailsTicket(ticket.id)">Ver detalles</a>
                        </div>
                        <div class="d-flex">
                            <div :class="[`ticket-status-${getStatus(ticket.status).label.toLowerCase()}`]" class="container-status">
                                {{getStatus(ticket.status).label}}
                            </div>
                        </div>
                    </div>
                </card>
            </div>
        </div>
        <ticket-details :ticket="selectedTicket" @closeModal="showDetails = false" @refresh="getTickets" v-if="showDetails"/>
    </div>
</template>
<script>
import http from 'axios';
import MainInput from '../../../common/components/inputs/MainInput.vue';
import MainButton from '../../../common/components/buttons/MainButton.vue';
import { mapGetters } from 'vuex';
import Card from '../../../common/components/cards/Card.vue';
import IconCustomerService from '../../layout/assets/svg/IconCustomerService.vue';
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import vueDropzone from 'vue2-dropzone'
import IconAddImage from '../../../common/svg/iconAddImage.vue';
import MainSelect from '../../../common/components/selects/MainSelect.vue';
import TicketDetails from '../components/modals/TicketDetails.vue';

export default {
    components: { MainInput, MainButton, Card, IconCustomerService, vueDropzone, IconAddImage, MainSelect, TicketDetails },
    name: "Tickets",
    data(){
        return {
            tickets: [],
            api: 'https://mundomovil-support.freshdesk.com/api/v2/',
            form:{
                name: null,
                email: null,
                subject: null,
                description: null,
                status: null,
                priority: null,
                unique_external_id: null,
                custom_fields:{
                    cf_asuntos: null
                }
            },
            attachments: [],
            status: null,
            dropzoneOptions: {
                url: '/',
                maxFiles: 6,
                thumbnailWidth: 200,
                resizeQuality: .6,
                addRemoveLinks: true,
                autoProcessQueue: false,
                resizeWidth: 1000,
                resizeHeight: 1000,
                acceptedFiles: ".jpeg, .jpg, .png",
            },
            subjects: [],
            selectedOption: {value: 0, title: 'Selecciona algo'},
            selectedTicket: null,
            showDetails: false
        }
    },
    computed: {
        ...mapGetters({
            isLogged: 'auth/isLogged',
            user: 'auth/userData',
        }),
        filterOptions(){
            let list = [{value: 0, title: 'Selecciona algo'}]
            this.subjects.forEach(subject => {
                list.push({value: subject.value, title: subject.label})
            });
            return list;
        }
    },
    methods: {
        test(){
            this.$refs.category.$v.modelValue.$touch();
        },
        async getTickets(){
            try {
                const response = await http.get(`${this.api}tickets`, 
                    {
                        auth: {
                            username: 'dbss7KE5OdAuKTy3L',
                            password: 'x'
                        },
                        params: {
                            unique_external_id: this.user.entity.code,
                            include: "description",
                        }
                    }
                )
                this.tickets = response.data
            } catch (error) {
                console.log(error);
            }
        },
        async afterComplete(upload){
            try {
                this.attachments.push({ name: upload.name, file: upload });
            } catch (error) {
                console.log(error)
            }
        }, 
        async getDetailsTicket(id){
            try {
                const response = await http.get(`${this.api}tickets/${id}`, 
                    {
                        auth: {
                            username: 'dbss7KE5OdAuKTy3L',
                            password: 'x',
                        },
                        params: {
                            include: "conversations",
                        }
                    }
                )
                this.selectedTicket = response.data;
                this.showDetails = true;
            } catch (error) {
                console.log(error);
            }
        },
        async uploadImgTicket(id){
            let formdata = new FormData()
            for (let i = 0; i < this.attachments.length; i++) {
                formdata.append('attachments[]', this.attachments[i].file,this.attachments[i].name);
            }
            try {
                const response = await http.put(`${this.api}tickets/${id}`,
                    formdata, 
                    {
                        auth: {
                            username: 'dbss7KE5OdAuKTy3L',
                            password: 'x'
                        }
                    },
                )
                console.log(response);
            } catch (error) {
                console.log(error)
            }
        },
        async createTicket(){
            if(this.isLogged){
                this.form.name = `${this.user.entity.first_name} ${this.user.entity.last_name}`;
                this.form.email = this.user.email;
                this.form.unique_external_id = this.user.entity.code;
            }
            this.form.custom_fields.cf_asuntos = this.selectedOption.value;
            this.form.status = 2;
            this.form.priority = 1;
            // this.$refs.ticketSubject.$touch();
            try {
                const response = await http.post(`${this.api}tickets`, 
                    {...this.form},
                    {
                        auth: {
                            username: 'dbss7KE5OdAuKTy3L',
                            password: 'x'
                        },
                    },
                )
                await this.uploadImgTicket(response.data.id)
                await this.getTickets()
                this.form = {
                    name: null,
                    email: null,
                    subject: null,
                    description: null,
                    status: null,
                    priority: null,
                    unique_external_id: null
                }
            } catch (error) {
                console.log(error);
            }
        },
        async getListStatus(){
            try { 
                const status = await http.get(`${this.api}admin/ticket_fields/73000979786`,
                    {   auth: {
                            username: 'dbss7KE5OdAuKTy3L',
                            password: 'x'
                        },
                    }
                )
                this.status = status.data 
            } catch (error) {
                console.log(error);
            }
        },
        async getListSubjects(){
            try { 
                const subjects = await http.get(`${this.api}admin/ticket_fields/73001033773`,
                    {   auth: {
                            username: 'dbss7KE5OdAuKTy3L',
                            password: 'x'
                        },
                    }
                )
                this.subjects = subjects.data.choices;
            } catch (error) {
                console.log(error);
            }
        },
        getStatus(id){
            let filterState = this.status.choices.filter((state) => state.id == id)
            return filterState[0]
        },
    },
        async created(){
            await this.getListStatus()
            await this.getListSubjects()
            await this.getTickets()
        }
    }
</script>

<style scoped>
    .ticket-icon{
        background: #e5f9fd;
        width: 35px;
        display: flex;
        height: 35px;
        justify-content: center;
        justify-content: c;
        border-radius: 50%;
        padding: 0px;       
    }
    .profile-image{
        border-radius: 50%;
        height: 35px;
        width: 35px;
        overflow: hidden;
        object-fit: cover;
    }
    .main-input::v-deep > .input-group{
        box-shadow: 0px 0px 3px 1px #f0f3f8;
        border-radius: 8px;
    }
    .main-input::v-deep > .input-group > span, .main-input::v-deep > .input-group > input{
        border: 0;
    }
    textarea{
        border-radius: 10px;
        border: 0;
        font-size: 14px;
        box-shadow: 0px 0px 3px 1px #f0f3f8;
    }
    .ticket-form{
        position: sticky;
        top: 9%;
    }
    .container-status{
        width: 120px;
        text-align: center;
        border-radius: 10px;
    }
    .vue-dropzone {
        border: none;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='%23C6D4DBFF' stroke-width='3' stroke-dasharray='16' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        border-radius: 15px;
    }
    textarea:focus{
        box-shadow: 0px 0px 3px 1px #f0f3f8 !important
    }
</style>
