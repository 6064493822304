import { render, staticRenderFns } from "./IconCustomerService.vue?vue&type=template&id=7373c67a&scoped=true"
import script from "./IconCustomerService.vue?vue&type=script&lang=js"
export * from "./IconCustomerService.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7373c67a",
  null
  
)

export default component.exports